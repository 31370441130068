import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { renderAsync } from 'docx-preview';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Text, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, VStack, HStack, Input, Flex, Card, Tooltip, IconButton, Container } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import axios from 'axios';
import moment from 'moment';

import { ReactComponent as PreviewFileIcon } from '../../images/preview_file.svg';
import { ReactComponent as DownloadFileIcon } from '../../images/download_file.svg';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs`;


const CaseFilesTab = ({ caseName, caseID }) => {
    const [caseFiles, setCaseFiles] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileTag, setFileTag] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const previewRef = useRef(null);

    const onDrop = (acceptedFiles) => {
        setSelectedFile(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleUpload = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedFile(null);
        setFileTag('');
    };

    useEffect(() => {
        fetchCaseFiles();
    }, []);


    const fetchCaseFiles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/list_casefiles?case_id=${caseID}`);
            setCaseFiles(response.data);
        } catch (error) {
            console.error('Error fetching case files:', error);
        }
    };

    useEffect(() => {
        if (selectedFile && previewRef.current) {
            if (selectedFile.type === 'application/pdf') {
                // PDF preview is handled by react-pdf
            } else if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                renderAsync(selectedFile, previewRef.current);
            }
        }
    }, [selectedFile]);

    const fetchCaseFileUrl = async (fileID) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/get_casefile_url?file_id=${fileID}`);
            return response.data.presigned_url;
        } catch (error) {
            console.error('Error fetching case file URL:', error);
        }
    };

    const handleDownload = async (fileID) => {
        const url = await fetchCaseFileUrl(fileID);
        window.open(url, '_blank');
    };

    const handlePreview = async (fileID) => {
        const url = await fetchCaseFileUrl(fileID);
        window.open(url, '_blank');
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleFileUpload = async () => {
        if (!selectedFile || !fileTag) return;

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('tag', fileTag);
        formData.append('case_id', caseID);
        try {
            await axios.put(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/upload_casefile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // Handle successful upload (e.g., show success message, refresh file list)
            handleCloseModal();
        } catch (error) {
            // Handle error (e.g., show error message)
            console.error('Error uploading file:', error);
        }
    };

    return (
        <Box>
            <Flex mb={4}>
                <Button onClick={handleUpload} variant={'secondary'}>Upload File</Button>
            </Flex>
            {caseFiles.map((file) => (
                <Card shadow={'none'} my={2} px={6} py={4} border={'1px solid'} borderColor={'gray.200'}>
                    <Flex justifyContent={'space-between'} align={'start'}>
                        <VStack align="start" spacing={2}>
                            <Text fontWeight="bold" color={'#000'} noOfLines={2}>
                                {file.tag}
                            </Text>
                            <Text fontSize="sm" color="gray.500">
                                Uploaded by <b>{file.uploaded_by}</b>
                            </Text>
                            <Text fontSize="xs" color="gray.400">
                                {moment(file.created_at * 1000).fromNow()}
                            </Text>
                        </VStack>
                        <HStack spacing={4} justifyContent="flex-end" align={'end'}>
                            <Tooltip label="Preview file">
                                <IconButton variant={'ghost_muted'} onClick={() => handlePreview(file.file_id)}><PreviewFileIcon fill={'#a8a8a8'} /></IconButton>
                            </Tooltip>
                            <Tooltip label="Download file">
                                <IconButton variant={'ghost_muted'} onClick={() => handleDownload(file.file_id)}><DownloadFileIcon fill={'#a8a8a8'} /></IconButton>
                            </Tooltip>
                        </HStack>
                    </Flex>
                </Card>
            ))}
            <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Upload Case Files - {caseName}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody position="relative">
                        <VStack spacing={4} height="80vh" maxHeight={'800px'}>
                            {!selectedFile ? (
                                <Box {...getRootProps()} w="100%" h="100%" border="2px dashed" borderColor={isDragActive ? "blue.500" : "gray.300"} borderRadius="md" p={4} display="flex" alignItems="center" justifyContent="center">
                                    <input {...getInputProps()} />
                                    <Text textAlign="center">
                                        {isDragActive ? "Drop the files here..." : "Drag 'n' drop some files here, or click to select files"}
                                    </Text>
                                </Box>
                            ) : (
                                <>
                                    <Box width="100%" height="100%" overflowY="auto">
                                        {selectedFile.type === 'application/pdf' ? (
                                            <Document
                                                file={selectedFile}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                            >
                                                <Page pageNumber={pageNumber} width={window.innerWidth * 0.7} />
                                            </Document>
                                        ) : (
                                            <div className='docx-preview-upload-preview' ref={previewRef} style={{ width: '100%', height: '100%' }}></div>
                                        )}
                                    </Box>
                                    <Card
                                        position="absolute"
                                        bottom="4"
                                        left="50%"
                                        transform="translateX(-50%)"
                                        bg="white"
                                        boxShadow="lg"
                                        borderRadius="md"
                                        p={4}
                                        width="90%"
                                        zIndex={99}
                                        border="1px solid"
                                        borderColor="gray.200"
                                        backgroundColor="green.50"
                                    >
                                        <Flex justifyContent="space-between" alignItems="end">
                                            <VStack alignItems={'flex-start'} spacing={2} flex={1} ml={4}>
                                                <Text fontWeight="bold">{selectedFile.name}</Text>
                                                <HStack>
                                                    <Input background={'white'} placeholder="Add tag" value={fileTag} onChange={(e) => setFileTag(e.target.value)} />
                                                    <Button variant={'default_muted'}>Done</Button>
                                                </HStack>
                                            </VStack>
                                            <Button variant={'secondary'} ml={4} onClick={handleFileUpload}>Upload</Button>
                                        </Flex>
                                    </Card>
                                </>
                            )}
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default CaseFilesTab;