import React, { useState } from "react";
import axios from 'axios';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  useToast
} from '@chakra-ui/react';

const InviteMembersModal = ({ isOpen, onClose }) => {
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserRole, setNewUserRole] = useState('Member');
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    
    const handleInviteUser = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_BASE_PATH}/invite_member`, {
          email: newUserEmail,
          role: newUserRole
        });

        if (response.status === 200) {
          setIsLoading(false);
          toast({
            title: "User Invited",
            description: "The member has been invited via email.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "An error occurred while inviting the member.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      setNewUserEmail('');
      setNewUserRole('Member');
      onClose();
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter email address"
              value={newUserEmail}
              onChange={(e) => setNewUserEmail(e.target.value)}
              mb={4}
            />
            <Select
              value={newUserRole}
              onChange={(e) => setNewUserRole(e.target.value)}
            >
              <option value="Member">Member</option>
              <option value="Admin">Admin</option>
              <option value="Super Admin">Super Admin</option>
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={2}>
              Cancel
            </Button>
            <Button variant={'secondary'} mr={3} onClick={handleInviteUser} isLoading={isLoading}>
              Send Invite
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  export default InviteMembersModal