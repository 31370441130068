import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Container,
  Card,
  Text,
  Flex,
  HStack,
  VStack,
  Badge,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tooltip,
  Divider,
  Button
} from '@chakra-ui/react';

import OverviewTab from './OverviewTab.jsx';
import CaseFilesTab from './CaseFilesTab.jsx';
import ActivityTab from './ActivityTab.jsx';

import SelectDropdown from '../../components/SelectDropdown.jsx/index.jsx';
import { ReactComponent as InfoIcon } from '../../images/tell_me_more.svg';

import './index.scss';

const CaseView = () => {
  const { caseID } = useParams();
  const [caseDetails, setCaseDetails] = useState(null);

  useEffect(() => {
    const fetchCaseDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/case_details?case_id=${caseID}`);
        setCaseDetails(response.data);
      } catch (error) {
        console.error('Error fetching case details:', error);
      }
    };

    fetchCaseDetails();
  }, [caseID]);

  if (!caseDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-content-margin">
      <Container maxW={'container.lg'} mt={8}>
        <Card p={[4, 6]}>
          <Box>
            <HStack justifyContent={'space-between'}>
              <VStack alignItems={'flex-start'}>
                <Text variant={'page_title'}>
                  {caseDetails.case_name}
                </Text>
                <Tooltip
                  hasArrow
                  placement="bottom-start"
                  label="Add Case Overview to move to the next step"
                >
                  <Badge mt={2} variant={'case_status'}>
                    <HStack>
                      <InfoIcon fill="#000000" />
                      <Text fontSize={'0.85rem'} fontWeight={'bold'}>
                        {caseDetails.status.text}
                      </Text>
                    </HStack>
                  </Badge>
                </Tooltip>
              </VStack>
              <Button variant={'secondary'}>View Analysis</Button>
            </HStack>
          </Box>
          <Divider mt={4} variant={'default'} />
          <Flex mt={6}>
            <HStack spacing={6}>
              <VStack spacing={1} alignItems={'flex-start'}>
                <Text fontWeight={'600'}>Assignee</Text>
                <SelectDropdown
                  options={[
                    {
                      id: 1,
                      option: 'test@test.com',
                      profilePicture: 'https://via.placeholder.com/150',
                    },
                    {
                      id: 2,
                      option: 'test2@test.com',
                      profilePicture: 'https://via.placeholder.com/150',
                    },
                  ]}
                  onChange={() => {}}
                  value={caseDetails.assigned_to}
                />
              </VStack>
              <VStack spacing={1} alignItems={'flex-start'}>
                <Text fontWeight={'600'}>External Collaborators</Text>
                <SelectDropdown
                  isMultiSelect={true}
                  options={[
                    {
                      id: 1,
                      option: 'test@test.com',
                      profilePicture: 'https://via.placeholder.com/150',
                    },
                    {
                      id: 2,
                      option: 'test2@test.com',
                    },
                    {
                      id: 3,
                      option: 'evil@test.com',
                    },
                  ]}
                  onChange={() => {}}
                  value={caseDetails.external_collaborators}
                />
              </VStack>
            </HStack>
          </Flex>
        </Card>
        <Tabs mt={8} isFitted variant="default">
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Case Files</Tab>
            <Tab>Activity
                <Badge variant={'default_strong'} ml={2}>3</Badge>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0} mt={4}>
                <OverviewTab />
            </TabPanel>
            <TabPanel p={0} mt={10}>
                <CaseFilesTab caseName={caseDetails.case_name} caseID={caseID} />
            </TabPanel>
            <TabPanel p={0} mt={4}>
                <ActivityTab caseID={caseID}/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </div>
  );
};

export default CaseView;
