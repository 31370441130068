import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Card,
  Text,
  Box,
  VStack,
  HStack,
  Select,
  Button,
  useDisclosure,
  Divider,
  Flex,
  Switch,
} from '@chakra-ui/react';

import { ReactComponent as InviteUserIcon } from '../../images/invite-user.svg';
import InviteMembersModal from './InviteMembersModal';
import { useStatsigClient } from '@statsig/react-bindings';
import { useGateValue } from '@statsig/react-bindings'
import {formatDistanceToNow } from 'date-fns'
const Settings = () => {
  const [members, setMembers] = useState([]);
  const { client } = useStatsigClient();
  const isEmailPrefsEnabled = useGateValue("email_prefs_enabled");

  useEffect(() => {
    fetchAnalysisHistory()
  }, [])


  const fetchAnalysisHistory = async () => {
    const url = `${process.env.REACT_APP_BASE_PATH}/members`;
    const response = await axios.get(url)
    setMembers(response.data.members)
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [invitedMembers, setInvitedMembers] = useState([]);

  useEffect(() => {
    fetchInvitedMembers();
  }, []);

  const fetchInvitedMembers = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_PATH}/invited_members`;
      const response = await axios.get(url);
      setInvitedMembers(response.data.invited_members);
    } catch (error) {
      console.error("Error fetching invited members:", error);
    }
  };

  const formatTimeLeft = (expirationTime) => {
    const now = new Date();
    const expiration = new Date(expirationTime);
    const timeLeft = expiration - now;

    if (timeLeft <= 0) {
      return "Expired";
    }

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} left`;
    } else {
      return `${hours} hour${hours > 1 ? 's' : ''} left`;
    }
  };

  const handlePermissionChange = (userId, newRole) => {
    // client.logEvent('add_to_cart', 'SKU_12345', {
    //   price: '9.99',
    //   item_name: 'diet_coke_48_pack',
    // });

    // setUsers(
    //   users.map(user =>
    //     user.id === userId ? { ...user, role: newRole } : user
    //   )
    // );
  };

  return (
    <div className="page-content-margin">
      <Container maxW="container.md">
        {isEmailPrefsEnabled && <Card bg="white" mt={10} p={[4, 6]} borderRadius={4}>
          <Text variant={'section_heading'} mb={4}>
            Email Preferences
          </Text>

          <Flex align={'center'} justify={'space-between'}>
            <VStack spacing={0} align={'start'} display={'flex'}>
              <Text color={'gray.800'} fontWeight={'600'}>New member</Text>
              <Text>Notify me when a new member joins</Text>
            </VStack>
            <Switch variant={'default'} size={'lg'}></Switch>
          </Flex>


          <Divider variant={'default'} my={4} />

          <Flex align={'center'} justify={'space-between'}>
            <VStack spacing={0} align={'start'} display={'flex'}>
              <Text color={'gray.800'} fontWeight={'600'}>New Case Added</Text>
              <Text>Allow members from the same domain</Text>
            </VStack>
            <Switch variant={'default'} size={'lg'}></Switch>
          </Flex>
        </Card>}

        <Card bg="white" mt={10} p={[4, 6]} borderRadius={4}>
          <Text variant={'section_heading'} mb={4}>
            Invite users
          </Text>

          <Flex align={'center'} justify={'space-between'}>
            <VStack spacing={0} align={'start'} display={'flex'}>
              <Text color={'gray.800'} fontWeight={'600'}>Whitelist domain</Text>
              <Text maxW={'95%'}>
                Allow members from <b>bayer.com</b> to join as a <b>Read Only Member</b>
              </Text>
            </VStack>
            <Switch variant={'default'} size={'lg'}></Switch>
          </Flex>

        {invitedMembers && invitedMembers.length > 0 && (
          <Box>
          <Divider variant={'default'} my={4} />
            <Text color={'gray.800'} fontWeight="bold" mb={2}>Pending Invitations</Text>
            <VStack spacing={2} align="stretch">
              {invitedMembers.map((member, index) => (
                <Flex key={index} justify="space-between" align="center">
                  <Text>{member.email}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {formatDistanceToNow(new Date(member.expires_at), { addSuffix: false })} left to accept
                  </Text>
                </Flex>
              ))}
            </VStack>
          </Box>
        )}
          <Divider variant={'default'} my={4} />
        <Button
          variant={'default_muted'}
          leftIcon={
            <InviteUserIcon style={{ marginRight: '8px' }} fill="#000" />
          }
          onClick={onOpen}
        >
          Invite New User
        </Button>
        </Card>

        <Card bg="white" mt={6} p={[4, 6]} borderRadius={4}>
          <Flex justify={'space-between'} alignItems={'baseline'}>
            <Text variant={'section_heading'} mb={0}>
              Workspace Members
            </Text>
          </Flex>
            <Text mb={4}>
              Members who have access to all cases in the workspace
            </Text>
          <VStack spacing={2} align="stretch" divider={<Divider />}>
            {members.map(member => (
              <Box key={member.member_id} py={2}>
                <HStack justify="space-between" align="center">
                  <VStack align="start" spacing={0}>
                    <Text fontWeight="bold">{member.email}</Text>
                    <Text color="gray.500">Joined {member.joined_at}</Text>
                  </VStack>
                  <Select
                    value={member.role}
                    onChange={e =>
                      handlePermissionChange(member.member_id, e.target.value)
                    }
                    width="auto"
                  >
                    <option value="Member">Member</option>
                    <option value="Admin">Admin</option>
                    <option value="Super Admin">Super Admin</option>
                  </Select>
                </HStack>
              </Box>
            ))}
          </VStack>
        </Card>
      </Container>

      <InviteMembersModal isOpen={isOpen} onClose={onClose} />
    </div>
  );
};

export default Settings;
