import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip } from '@chakra-ui/react';

import AuditHistoryPageIcon from './AuditHistoryPageIcon';
import QuestionAnsweringIcon from './QuestionAnsweringIcon';
import SettingsIcon from './SettingsIcon';

const paths = [
    {
        key: 'question_answering',
        route: '/dispute_analysis',
        title: 'Consensus for Disputes',
        icon_name: 'QuestionAnsweringIcon' 
    },
    {
        key: 'analysis_history',
        route: '/analysis_history',
        title: 'Analysis History',
        icon_name: 'AuditHistoryPageIcon' 
    },
    {
        key: 'settings',
        route: '/settings',
        title: 'Settings',
        icon_name: 'SettingsIcon' 
    }
];

const hideHeaderPaths = ['/', '/logout', '/join', '/verify']

const NavigationHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeItem, setActiveItem] = useState(paths[0].key);
    const [shouldHideHeader, setShouldHideHeader] = useState(false)

    useEffect(() => {
        const url = location.pathname;
        setShouldHideHeader(hideHeaderPaths.includes(url))
        let path_key = ""; 
        paths.forEach((path) => {
            if(path.route === url) path_key = path.key
        })
        setActiveItem(path_key)
    }, [location.pathname])

    const handleNavigation = (route, key) => {
        setActiveItem(key);
        navigate(route);
    };

    const renderIcon = (iconName, state) => {
        switch (iconName) {
            case 'QuestionAnsweringIcon':
                return <QuestionAnsweringIcon isActive={state === 'active'} />;
            case 'AuditHistoryPageIcon':
                return <AuditHistoryPageIcon isActive={state === 'active'} />;
            case 'SettingsIcon':
                return <SettingsIcon isActive={state === 'active'} />;
            default:
                return null;
        }
    };

    return (
        <>
            {!shouldHideHeader && (
                <div className="navigation-header">
                    <div className="header-logo"></div>
                    <nav className="navigation-items">
                        {paths.map(({ key, route, title, icon_name }) => (
                            <Tooltip key={key} label={title} placement="right">
                                <div
                                    className={`nav-item ${activeItem === key ? 'active' : ''}`}
                                    onClick={() => handleNavigation(route, key)}
                                >
                                    {renderIcon(icon_name, activeItem === key ? 'active' : 'inactive')}
                                    {/* <span>{title}</span> */}
                                </div>
                            </Tooltip>
                        ))}
                    </nav>
                </div>
            )}
        </>
    );
}

export default NavigationHeader;
