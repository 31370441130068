import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    HStack,
    Text,
    Avatar,
    Divider,
    Container,
    Textarea,
    IconButton,
    Icon,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Card,
    Tooltip
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { ReactComponent as OptionsIcon } from '../../images/options.svg';
import { ReactComponent as SendIcon } from '../../images/send.svg';
import activityBodyText from '../../constants/activity_body_text.json';

const ActivityTab = ({ caseID }) => {
    const [activities, setActivities] = useState([]);
    const [newComment, setNewComment] = useState('');

    useEffect(() => {
        fetchComments();
    }, []);

    const fetchComments = async () => {
        try {
            const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/case_activities?case_id=${caseID}`;
            const response = await axios.get(url);
            console.log(response.data);
            setActivities(response.data);
        } catch (error) {
            console.error('Error fetching activities:', error);
        }
    };

    const handleAddComment = async () => {
        const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/add_comment`;
        try {
            const response = await axios.post(url, {
                case_id: caseID,
                comment_body: newComment,
            });
            console.log(response.data);
            fetchComments();
            setNewComment('');
        } catch (error) {
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleAddComment();
        }
    };

    return (
        <Box>
            <VStack spacing={4} align="stretch">
                {activities?.length === 0 ? (
                    <Box textAlign="center" py={8}>
                        <Text fontSize="lg" color="gray.500">
                            No activities yet. Be the first to add a comment!
                        </Text>
                    </Box>
                ) : (
                    activities.map((activity) => (
                        <>
                            {activity.activity.type === 'comment_added' ? <Card key={activity.id} py={3} px={4} borderRadius="md">
                                <HStack spacing={3} align="start" justifyContent="space-between">
                                    <HStack spacing={3} align="center" justifyContent="space-between">
                                        <Avatar size="xs" name={activity.user} />
                                        <VStack align="start" spacing={1}>
                                            <HStack>
                                                <Text fontWeight="bold">{activity.user_email}</Text>
                                                <Text fontSize="sm" color="gray.500">
                                                    {moment(activity.created_at * 1000).fromNow()}
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </HStack>
                                    <Menu>
                                        <MenuButton
                                            as={IconButton}
                                            aria-label='Options'
                                            icon={<OptionsIcon fill='#a8a8a8' />}
                                            variant='ghost'
                                            size='sm'
                                        />
                                        <MenuList>
                                            <MenuItem>Edit</MenuItem>
                                            <MenuItem>Delete</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </HStack>
                                <Text>{activity.activity.metadata.comment_body}</Text>
                            </Card> : <Box>
                                <Text>
                                    {activityBodyText[activity.activity.type].replace(
                                        /%([^%]+)%/g,
                                        (match, key) => {
                                            const keys = key.split('.');
                                            return keys.reduce((obj, k) => obj?.[k], activity) || match;
                                        }
                                    )}
                                </Text>
                                <Tooltip label={moment(activity.created_at * 1000).format('LLLL')} placement="bottom-start" hasArrow>
                                    <Text display={'inline-block'} fontSize="sm" color="gray.500">{moment(activity.created_at * 1000).fromNow()}</Text>
                                </Tooltip>
                            </Box>}
                        </>
                    ))
                )}

                <Divider />
                <Box position="relative">
                    <Textarea
                        variant={'default'}
                        bg={'white'}
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        onKeyDown={handleKeyPress}
                        placeholder="Add a comment"
                        size="md"
                        pr="40px"
                    />
                    <IconButton
                        icon={<Icon as={SendIcon} />}
                        variant={'default'}
                        cursor={'pointer'}
                        onClick={handleAddComment}
                        position="absolute"
                        bottom="8px"
                        right="8px"
                        size="sm"
                        zIndex={99}
                    />
                </Box>
            </VStack>
        </Box>
    );
};

export default ActivityTab;
