import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  VStack,
  Text,
  Link,
  Container,
  Skeleton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Tooltip,
  Kbd,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import axios from 'axios';

import useTextSelection from '../../hooks/useTextSelection';
import VisualTimeline from '../MultiDocResults/VisualTimeline';
import ModifiersBar from './ModifiersBar';
import RenderInconsistencies from './RenderInconsistencies';
import RenderKeyIssues from './RenderKeyIssues';
import ActionButtons from '../../components/TextActions';
import { showReferences } from '../../helpers/ShowReferences';

const sections = [
  { id: 'timeline', title: 'Timeline', fetch_key: 'date_events' },
  { id: 'summary', title: 'Summary', fetch_key: 'summaries' },
  { id: 'key_issues', title: 'Key issues', fetch_key: 'key_issues' },
  {
    id: 'inconsistencies',
    title: 'Inconsistencies',
    fetch_key: 'inconsistencies',
  },
];

const modifiers = {
  timeline: {
    mode: 'additive',
    values: [
      { title: 'Transactions', key: 'transaction' },
      { title: 'Correspondence', key: 'correspondence' },
      { title: 'Milestones', key: 'milestone' },
      { title: 'Miscellaneous', key: 'miscellaneous' },
    ],
  },
  summary: {
    mode: 'exclusive',
    values: [
      { title: 'Complete Dispute', key: 'complete_dispute' },
      { title: "Claimant's Pleading", key: 'claimant_pleading' },
      { title: "Respondent's Pleading", key: 'respondent_pleading' },
    ],
  },
  key_issues: {},
  inconsistencies: {
    mode: 'tiered',
    values: [
      { title: 'Claimant', key: 'claimant_inconsistencies' },
      { title: 'Respondent', key: 'respondent_inconsistencies' },
    ],
  },
};

const AnalysisTab = ({ caseID, documents, goToDoc }) => {
  const [activeSection, setActiveSection] = useState(sections[0].id);
  const [sectionData, setSectionData] = useState({});
  const [loading, setLoading] = useState({});
  const [currentSectionModifiers, setCurrentSectionModifiers] = useState([]);
  const [appliedModifiers, setAppliedModifiers] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isTextActionLoading, setIsTextActionLoading] = useState(false);
  const [selectedTextAction, setSelectedTextAction] = useState();
  const containerRef = useRef(null);
  const sectionRefs = useRef({});
  const selectionWrapperRef = useRef(null); // Shows the onSelect actions only within this ref

  const {
    selectedText,
    selectionPosition,
    selectedSection,
    setSelectedText,
    selectionMetadata,
    resetSelection,
  } = useTextSelection(selectionWrapperRef, sectionRefs);

  useEffect(() => {
    if (selectedSection) {
      console.log('Selection occurred in section:', selectedSection);
    }
  }, [selectedSection]);

  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 750); // Increased to account for both animations
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  useEffect(() => {
    setCurrentSectionModifiers(modifiers[activeSection]);
  }, [activeSection]);

  useEffect(() => {
    // Fetch data for the first section on initial render
    fetchSectionData(sections[0].id);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const containerTop = container.getBoundingClientRect().top;
      const containerHeight = container.clientHeight;

      let currentSection = sections[0].id;

      for (const section of sections) {
        const sectionElement = sectionRefs.current[section.id];
        if (!sectionElement) continue;

        const sectionTop =
          sectionElement.getBoundingClientRect().top - containerTop;
        const sectionBottom = sectionTop + sectionElement.clientHeight;

        if (
          sectionTop <= containerHeight / 3 &&
          sectionBottom > containerHeight / 3
        ) {
          currentSection = section.id;
          break;
        }
      }

      if (currentSection !== activeSection) {
        setActiveSection(currentSection);
        fetchSectionData(currentSection);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [activeSection]);

  const fetchSectionData = async id => {
    const section = sections.find(s => s.id === id);
    if (section.fetch_key && !sectionData[id]) {
      setLoading(prev => ({ ...prev, [id]: true }));
      try {
        const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/fetch_result`;
        const response = await axios.get(url, {
          params: {
            case_id: caseID,
            key: section.fetch_key,
            mock: false,
          },
        });
        const { data } = response;
        setSectionData(prev => ({ ...prev, [id]: data }));
      } catch (error) {
        console.error(`Error fetching data for ${id}:`, error);
      } finally {
        setLoading(prev => ({ ...prev, [id]: false }));
      }
    }
  };
  const handleSectionLinkClick = id => {
    const sectionElement = document.getElementById(id);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    fetchSectionData(id);
  };

  const handleExportCompleteAnalysis = async () => {
    try {
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/export?case_id=${caseID}`;
      const response = await axios.get(url, {
        responseType: 'blob', // Important: set the response type to blob
      });
      // Create a Blob from the response data
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      // Create a link element and trigger the download
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = response.headers['content-disposition']
        ? response.headers['content-disposition']
            .split('filename=')[1]
            .replace(/"/g, '')
        : 'case_analysis.docx';
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Clean up the URL object
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error exporting analysis:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const onModifiersChange = newModifiers => {
    setAppliedModifiers(newModifiers);
  };

  const onElaborateSelect = async actionType => {
    console.log('selectionMetadata', selectionMetadata);
    console.log('actionType', actionType);
    console.log('selectedText', selectedText);
    const response = await showReferences(
      selectedText,
      'fact_check',
      caseID,
      false,
      setIsTextActionLoading,
      setSelectedTextAction,
      goToDoc,
      setSelectedText,
      selectionMetadata
    );
    setTimeout(() => {
      resetSelection();
    }, 1500);
  };

  const onAddToSnippetsSelect = async () => {
    setIsTextActionLoading(true);
    const payload = {
      case_id: caseID,
      snippet_body: selectedText,
      snippet_metadata: selectionMetadata,
    };
    const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/add_to_snippets`;
    const response = await axios.post(url, payload);
    setIsTextActionLoading(false);
  };
  
  const getSecondLayerModifiers = appliedFirstLevelModifier => {
    if (!sectionData || !activeSection || !sectionData.length) return [];

    const currentSectionContent =
      sectionData[activeSection]?.[appliedFirstLevelModifier];
    return [
      ...new Set(
        currentSectionContent?.map(item => item.source_document) || []
      ),
    ];
  };

  const renderSectionContent = id => {
    if (loading[id]) {
      return (
        <VStack align="stretch" spacing={4}>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </VStack>
      );
    }
    if (sectionData[id]) {
      let timelineFilterMode = appliedModifiers.filter(modifier =>
        modifiers.timeline.values.some(value => value.key === modifier)
      );

      if (id === 'timeline') {
        return (
          <VisualTimeline
            goToDoc={goToDoc}
            timelineData={sectionData[id].timeline}
            filterMode={timelineFilterMode}
          />
        );
      } else if (id === 'inconsistencies') {
        const additionalModifiers = appliedModifiers.slice(1);

        if (appliedModifiers[0] === 'claimant_inconsistencies') {
          return (
            <div>
              <RenderInconsistencies
                data={sectionData[id]?.claimant_inconsistencies}
                party="Claimant"
                additionalModifiers={additionalModifiers}
              />
            </div>
          );
        } else if (appliedModifiers[0] === 'respondent_inconsistencies') {
          return (
            <div>
              <RenderInconsistencies
                data={sectionData[id]?.respondent_inconsistencies}
                party="Respondent"
                additionalModifiers={additionalModifiers}
              />
            </div>
          );
        }
      } else if (id === 'key_issues') {
        return (
          <div>
            <RenderKeyIssues data={sectionData[id]} />
          </div>
        );
      } else if (id === 'summary') {
        if (appliedModifiers[0] === 'claimant_pleading') {
          return (
            <div
            className='summary-container'
              data-section-type="claimant"
              data-section-document-source="Claimant's Pleading"
              dangerouslySetInnerHTML={{ __html: sectionData[id]?.claimant }}
            ></div>
          );
        } else if (appliedModifiers[0] === 'respondent_pleading') {
          return (
            <div
            className='summary-container'
              data-section-type="respondent"
              data-section-document-source="Respondent's Pleading"
              dangerouslySetInnerHTML={{ __html: sectionData[id]?.respondent }}
            ></div>
          );
        } else {
          return (
            <div
            className='summary-container'
              data-section-type="complete_summary"
              data-section-document-source="Pleadings"
              dangerouslySetInnerHTML={{ __html: sectionData[id]?.complete }}
            ></div>
          );
        }
      }
    }
    return (
      <VStack>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </VStack>
    );
  };

  return (
    <Flex h="85vh" position={'relative'}>
      <Flex w={'100%'} borderRight="1px solid" borderColor="gray.200">
        <Box w="200px" p={4}>
          <VStack
            justify={'space-between'}
            h={'100%'}
            alignItems={'flex-start'}
          >
            <VStack align="stretch" spacing={4}>
              {sections.map(({ id, title }) => (
                <Link
                  key={id}
                  onClick={() => handleSectionLinkClick(id)}
                  textTransform={'uppercase'}
                  fontWeight={'600'}
                  color={activeSection === id ? '#00B870' : '#B9B9B9'}
                  _hover={{ textDecoration: 'none', color: '#00B870' }}
                >
                  {title}
                </Link>
              ))}
            </VStack>
            <VStack>
              <Menu>
                <MenuButton
                  w={'100%'}
                  variant={'outline'}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  Export
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={handleExportCompleteAnalysis} minH="48px">
                    <span>Export Complete Analysis</span>
                  </MenuItem>
                  <MenuItem minH="40px" onClick={() => {}}>
                    <span>Export Notes</span>
                  </MenuItem>
                </MenuList>
              </Menu>
            </VStack>
          </VStack>
        </Box>

        <Box flex={1} overflowY="auto" position="relative" ref={containerRef}>
          <Container
            maxW="container.md"
            py={8}
            className="selection-wrapper-ref"
            ref={selectionWrapperRef}
          >
            {sections.map(({ id, title }) => (
              <Box
                className="dynamic-content-box"
                key={id}
                id={id}
                ref={el => (sectionRefs.current[id] = el)}
                minH="80vh"
                mb={8}
              >
                <Text fontSize="2xl" fontWeight="bold" mb={4}>
                  {title}
                </Text>
                <Box data-section-id={id}>{renderSectionContent(id)}</Box>
              </Box>
            ))}
          </Container>

          {currentSectionModifiers['values']?.length && (
            <ModifiersBar
              availableModifiers={currentSectionModifiers['values']}
              mode={currentSectionModifiers['mode']}
              onModifiersChange={onModifiersChange}
              getSecondLayerModifiers={getSecondLayerModifiers}
            />
          )}

          <ActionButtons
            isTextActionLoading={isTextActionLoading}
            selectedText={selectedText}
            selectionPosition={selectionPosition}
            onElaborateSelect={onElaborateSelect}
            onAddToSnippetsSelect={onAddToSnippetsSelect}
            hideActions={() => setSelectedText('')}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default AnalysisTab;
