import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
  HStack,
  Image,
  Badge,
  useColorModeValue,
  useToast,
  Progress,
  Container,
  Card
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as PdfIcon } from '../../images/pdf_icon.svg'
import { ReactComponent as DocxIcon } from '../../images/docx_icon.svg'

const OverviewTab = () => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [metadata, setMetadata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [loadingStep, setLoadingStep] = useState(0);
  const [loadingTime, setLoadingTime] = useState(0);

  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const toast = useToast();

  const loadingSteps = [
    { text: "Uploading document", duration: 3 },
    { text: "Creating an index", duration: 5 },
    { text: "Extracting key metadata", duration: 7 },
    { text: "Verifying results", duration: 5 },
  ];

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setInterval(() => {
        setLoadingTime((prevTime) => {
          const newTime = prevTime + 1;
          if (newTime >= loadingSteps[loadingStep].duration) {
            if (loadingStep < loadingSteps.length - 1) {
              setLoadingStep((prevStep) => prevStep + 1);
              return 0;
            } else {
              clearInterval(timer);
            }
          }
          return newTime;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isLoading, loadingStep]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      toast({
        title: "No file selected",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    setLoadingStep(0);
    setLoadingTime(0);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('case_id', 'dummy_case_id'); // Replace with actual case ID when available

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/extract_metadata`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.metadata) {
        setMetadata(response.data.metadata);
        setIsUploaded(true);
      } else {
        throw new Error('No metadata received from the server');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      toast({
        title: "Error uploading file",
        description: error.response?.data?.error || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleVerify = (index) => {
    const newMetadata = [...metadata];
    newMetadata[index].verified = true;
    setMetadata(newMetadata);
  };

  const handleReject = (index) => {
    const newMetadata = [...metadata];
    newMetadata[index].verified = false;
    setMetadata(newMetadata);
  };
  if (!isUploaded) {
    return (
      <Box>
        <Box mb={4} p={3} bg={bgColor} borderRadius="md" borderWidth={1} borderColor={borderColor}>
          <Flex justify={'space-between'}>
            <VStack spacing={0} align={'flex-start'}>
              <Text fontSize="lg" fontWeight="bold" color={'gray.800'}>
                Extract Metadata
              </Text>
              <Text>
                Upload a claimant pleading to extract key metadata.
              </Text>
            </VStack>
          </Flex>
        </Box>

        {file && (
          <Box bg={'white'} mb={4} py={3} px={5} borderWidth={1} borderRadius="md" borderColor={borderColor}>
            <Flex align="center" justify={'space-between'}>
              <HStack>{file.type === 'application/pdf' ? (
                <PdfIcon width={42} height={42} mr={8} />
              ) : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                <DocxIcon width={42} height={42} mr={8} />
              ) : null}
                <Text ml={4} fontSize={'lg'} color={'gray.800'} maxWidth={'320px'}>{file.name}</Text></HStack>
              <Button variant={'default'} onClick={handleUpload} isLoading={isLoading}>
                Start
              </Button>
            </Flex>
            {isLoading && (
              <Box bg={'green.50'} py={4} px={8} mt={4} borderRadius={'md'}>
                <Box height="50px">
                  <AnimatePresence>
                    <motion.div
                      key={loadingStep}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Text color={'gray.800'} mb={2} fontSize={'1.1rem'}>
                        {loadingSteps[loadingStep].text}
                      </Text>
                    </motion.div>
                  </AnimatePresence>
                </Box>
                <Progress size="sm" borderRadius={'full'} isIndeterminate colorScheme="green" />
              </Box>
            )}
          </Box>
        )}

        {!file && <Button
          variant={'default'}
          onClick={() => {
            document.getElementById('fileInput').click();
          }}
          isLoading={isLoading}
        >
          Upload Claimant Pleading
        </Button>}

        <input
          type="file"
          id="fileInput"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </Box>
    );
  }

  return (
    <Flex direction={{ base: 'column', md: 'row' }} spacing={4}>
      <Box flex={1} mr={{ base: 0, md: 4 }} mb={{ base: 4, md: 0 }}>
        <Image
          src="https://via.placeholder.com/300x400.png?text=Document+Preview"
          alt="Document Preview"
          borderRadius="md"
        />
      </Box>
      <VStack flex={2} align="stretch" spacing={4}>
        <Text fontSize="lg" fontWeight="bold">
          Extracted Metadata
        </Text>
        {metadata.map((item, index) => (
          <Card key={index} p={3} borderWidth={1} borderRadius="md" borderColor={borderColor}>
            <Flex justify="space-between" align="center">
              <VStack align="start" spacing={0}>
                <Text color={'gray.800'} fontWeight={'600'}>{item.field}</Text>
                <Text>{item.value}</Text>
              </VStack>
              <HStack>
                <Button
                  size="sm"
                  colorScheme={item.verified ? 'green' : 'gray'}
                  onClick={() => handleVerify(index)}
                >
                  Verify
                </Button>
                <Button
                  size="sm"
                  colorScheme={!item.verified ? 'red' : 'gray'}
                  onClick={() => handleReject(index)}
                >
                  Reject
                </Button>
              </HStack>
            </Flex>
          </Card>
        ))}
      </VStack>
    </Flex>
  );
};

export default OverviewTab;
