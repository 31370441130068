import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import {
  Text,
  Box,
  Flex,
  Button,
  HStack,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Badge,
  Card,
  InputGroup,
  InputLeftElement,
  Divider
} from '@chakra-ui/react';

import statusLabels from '../../constants/status.js';

import SelectDropdown from '../../components/SelectDropdown.jsx/index.jsx';
import { AuthContext } from './../../helpers/AuthContext';
import { ReactComponent as AddIcon } from '../../images/add.svg';
import { ReactComponent as InfoIcon } from '../../images/info.svg';
import { ReactComponent as SortIcon } from '../../images/sort.svg';
import { ReactComponent as ProfileIcon } from '../../images/profile.svg';
import { ReactComponent as DateRange } from '../../images/date_range.svg';
import { ReactComponent as SearchIcon } from '../../images/search.svg';
import './index.scss';

const AnalysisHistory = ({ globalState, setGlobalState }) => {
  const navigate = useNavigate();
  const { isLoading } = useContext(AuthContext);
  const [pastAudits, setPastAudits] = useState([]);
  const [isNewCaseModalOpen, setIsNewCaseModalOpen] = useState(false);
  const [newCaseName, setNewCaseName] = useState('');
  const [isCreatingCase, setIsCreatingCase] = useState(false);
  const toast = useToast();

  // New state for sort and filter
  const [sortOrder, setSortOrder] = useState('desc');
  const [filterOptions, setFilterOptions] = useState({
    created: { from: null, to: null },
    assignee: null,
    status: null
  });

  useEffect(() => {
    if (!isLoading) {
      fetchAnalysisHistory();
    }
  }, [isLoading, sortOrder, filterOptions]);

  const fetchAnalysisHistory = async () => {
    const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/case_list`;
    const requestBody = {
      sort: sortOrder,
      filter: {
        ...filterOptions,
        created: {
          from: filterOptions.created.from ? filterOptions.created.from.getTime() / 1000 : undefined,
          to: filterOptions.created.to ? filterOptions.created.to.getTime() / 1000 : undefined
        }
      }
    };
    try {
      const response = await axios.post(url, requestBody);
      setPastAudits(response.data);
    } catch (error) {
      console.error('Error fetching analysis history:', error);
      toast({
        title: "Error",
        description: "Failed to fetch analysis history. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const convertToRelativeTime = timestamp => {
    return moment(timestamp * 1000).fromNow();
  };

  const handleNavigateToAnalysis = async caseID => {
    navigate(`/case/${caseID}`);
  };

  const handleNewCase = () => {
    setIsNewCaseModalOpen(true);
  };

  const handleCreateNewCase = async () => {
    if (!newCaseName.trim()) {
      toast({
        title: "Error",
        description: "Please enter a case name",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsCreatingCase(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/create_case`, {
        case_name: newCaseName
      });

      if (response.data && response.data.case_id) {
        navigate(`/case/${response.data.case_id}`);
      } else {
        throw new Error('No case ID received from the server');
      }
    } catch (error) {
      console.error('Error creating new case:', error);
      toast({
        title: "Error",
        description: "Failed to create new case. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsCreatingCase(false);
      setIsNewCaseModalOpen(false);
      setNewCaseName('');
    }
  };

  const RenderAuditCard = ({ data }) => {
    return (
      <Card my={4} py={4} px={8}>
        <Flex justify={'space-between'} align={'center'}>
          <Box>
            <HStack>
              <VStack justify={'start'} align={'self-start'}>
                <Text fontWeight={600} color={'black'} fontSize={'1.2rem'}>
                  {data.case_name}
                </Text>

                <Badge mb={2} variant={'case_status'}>
                  <HStack>
                    <InfoIcon height={'18px'} width={'18px'} fill="#000000" />
                    <Text fontSize={'0.85rem'} fontWeight={'bold'}>
                      {data.status?.text}
                    </Text>
                  </HStack>
                </Badge>
                <HStack spacing={8}>
                  <VStack spacing={0} justify={'start'} align={'self-start'}>
                    <Text variant={'label'}>Assignee</Text>
                    <Text color={'gray.800'}>
                      {data.assigned_to || 'Unassigned'}
                    </Text>
                  </VStack>
                  <VStack spacing={0} justify={'start'} align={'self-start'}>
                    <Text variant={'label'}>Created</Text>
                    <Text color={'gray.800'}>
                      {data.created_at && convertToRelativeTime(data.created_at)}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </HStack>
          </Box>
          <Flex align={'center'}>
            <Button onClick={() => handleNavigateToAnalysis(data.case_id)}>
              View Case
            </Button>
          </Flex>
        </Flex>
      </Card>
    );
  };

  return (
    <Flex justifyContent={'center'} alignItems={'center'}>
      <Box className="analysis-history-wrapper" maxW={'860px'} mt={16}>
        <Card px={8} py={4}>
          <Flex align={'center'} justifyContent="space-between" mb={4}>
            <Box width={'60%'}>
              <InputGroup>
                <InputLeftElement mt={2} ml={3} pointerEvents='none'>
                  <SearchIcon fill='#a8a8a8' />
                </InputLeftElement>
                <Input variant={'search'} placeholder='Search cases' />
              </InputGroup>
            </Box>
            <Button onClick={handleNewCase} variant={'secondary'} leftIcon={<AddIcon fill='#fff' />}>New Case</Button>
          </Flex>
          <Divider variant={'default'} />
          <Flex w={'100%'} mt={4} justify={'space-between'}>
            <SelectDropdown
              onChange={(value) => setSortOrder(value)}
              isMultiSelect={false}
              shouldShowAssignee={false}
              placeholderText='Sort by'
              shouldShowSearch={false}
              isClearable={false}
              Icon={SortIcon}
              options={[
                { id: 'desc', option: 'Newest First' },
                { id: 'asc', option: 'Oldest First' }
              ]}
            />
            <HStack>
            <SelectDropdown
              onChange={(value) => setFilterOptions(prev => ({ ...prev, assignee: value }))}
              isMultiSelect={true}
              shouldShowAssignee={true}
              placeholderText='Assignee'
              shouldShowSearch={true}
              Icon={ProfileIcon}
              options={[
                { id: 1, option: 'John Doe' },
                { id: 2, option: 'Jane Smith' },
                { id: 3, option: 'Unassigned' }
              ]}
            />
            <SelectDropdown
              onChange={(value) => setFilterOptions(prev => ({ ...prev, status: value }))}
              isMultiSelect={false}
              shouldShowAssignee={false}
              placeholderText='Status'
              shouldShowSearch={true}
              Icon={InfoIcon}
              options={statusLabels}
            />
            <SelectDropdown
              onChange={(value) => setFilterOptions(prev => ({ ...prev, status: value }))}
              isMultiSelect={false}
              shouldShowAssignee={false}
              placeholderText='Created Date'
              shouldShowSearch={false}
              Icon={DateRange}
              options={[
                { id: 1, option: 'In Progress' },
                { id: 2, option: 'Completed' },
                { id: 3, option: 'On Hold' }
              ]}
            />
            </HStack>
          </Flex>
        </Card>
        <Box mt={4}>
          <Text>Showing {pastAudits.length} of {pastAudits.length} cases</Text>
        </Box>
        {pastAudits.map(data => (
          <RenderAuditCard key={data.case_id} data={data} />
        ))}
      </Box>
      <Modal isOpen={isNewCaseModalOpen} onClose={() => setIsNewCaseModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody mt={5}>
            <FormControl>
              <FormLabel>Case Name</FormLabel>
              <Input
              variant={'default'}
                autoFocus={true}
                value={newCaseName}
                onChange={(e) => setNewCaseName(e.target.value)}
                placeholder="Enter case name"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={() => setIsNewCaseModalOpen(false)}>Cancel</Button>
            <Button isDisabled={!newCaseName?.length} variant="secondary" mr={3} onClick={handleCreateNewCase} isLoading={isCreatingCase}>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
export default AnalysisHistory;
